import React, {useContext, useState} from "react";
import {LabeledButton} from "../../reusables/basic/LabeledButton";
import "./LoginModal.css";
import {KeycloakContext} from "../../KeycloakProvider";

type loginModalProps = {
  hideLoginWindow: () => void;
  isLoggedIn: boolean;
};

/**
 * A modal in the center of the screen for users to log in or out.
 *
 * @component
 * @props hideLoginWindow: a function to call when the user wants to leave the window without action
 * @props isLoggedIn: whether the user is already logged in
 */
const LoginModal = (props: loginModalProps) => {
  const [expandedView] = useState(false);
  const {keycloak} = useContext(KeycloakContext);

  if (props.isLoggedIn)
    return (
      <div className="loginModalBlurOverlay" onClick={props.hideLoginWindow}>
        <div
          className="loginModalExpandedContainer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={"loginModalContainer logoutModal"}>
            <div className={"loginInputsContainer"}>
              <LabeledButton
                label={"Benutzerverwaltung"}
                id={"loginSettingsButton"}
                importance="primary"
                onClick={() => {
                  keycloak.accountManagement();
                }}
              />
              <LabeledButton
                label={"Logout"}
                id={"loginLogoutButton"}
                importance="tertiary"
                onClick={() => {
                  keycloak.logout({
                    redirectUri: window.location.origin + "/",
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="loginModalBlurOverlay" onClick={props.hideLoginWindow}>
      <div
        className="loginModalExpandedContainer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className={
            "loginModalContainer" + (expandedView ? " expandedView" : "")
          }
        >
          {expandedView ? (
            <div className="loginInfoContainer">
              <h2 className="loginInfoHeader">
                Ein Überblick über den momentanen Status der Würzburger Bäume.
              </h2>
              <p className="loginInfoText">
                Dieses Tool informiert tagesaktuell über den Zustand der Bäume
                im Würzburger-Raum. Das Gartenamt der Stadt nutzt diese Daten um
                ressourcenschonend und schnell die Bewässerung der Bäume zu
                planen.
              </p>
              <p className="loginInfoText">
                Ein Algorithmus berechnet den momentanen Status des Baums und
                unterteilt diesen dann in einen von fünf Kategorien.
              </p>
              <div className="loginInfoGraphicContainer">
                <img src={""} />
              </div>
            </div>
          ) : null}
          {expandedView ? <div className="loginInfoDivider" /> : null}
          <div
            className={
              "loginInputsContainer" + (expandedView ? " expandedView" : "")
            }
          >
            {expandedView ? (
              <h3 className="loginInputHeader">Login für das Gartenamt</h3>
            ) : null}
            <div>
              <LabeledButton
                label={expandedView ? "Weiter" : "Login"}
                id={"loginProceedButton"}
                importance="primary"
                onClick={() => keycloak.login()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {LoginModal};
