import React from "react";
import App from "./views/App";
import KeycloakContext from "./KeycloakProvider";

function KeycloakInitWrapper() {
  return (
    <KeycloakContext>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </KeycloakContext>
  );
}

export default KeycloakInitWrapper;
